html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.user-image {
  border-radius: 50%;
  width: 7rem;
  border: 4px solid #ff7300;
}

.search-user-image {
  border-radius: 50%;
  width: 35px;
  margin-right: 10px;
  border: 3px #ff7300 solid;
}

.node-image {
  border: 10px #ff7300 solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50px;
  height: 50px;
}

.org-data-user-image {
  border: 3px #ff7300 solid;
}
